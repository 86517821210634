exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-baza-pytan-tsx": () => import("./../../../src/pages/baza-pytan.tsx" /* webpackChunkName: "component---src-pages-baza-pytan-tsx" */),
  "component---src-pages-blokuj-cholesterol-tsx": () => import("./../../../src/pages/blokuj-cholesterol.tsx" /* webpackChunkName: "component---src-pages-blokuj-cholesterol-tsx" */),
  "component---src-pages-dodaj-przepis-tsx": () => import("./../../../src/pages/dodaj-przepis.tsx" /* webpackChunkName: "component---src-pages-dodaj-przepis-tsx" */),
  "component---src-pages-eko-opakowania-tsx": () => import("./../../../src/pages/eko-opakowania.tsx" /* webpackChunkName: "component---src-pages-eko-opakowania-tsx" */),
  "component---src-pages-fakty-i-mity-tsx": () => import("./../../../src/pages/fakty-i-mity.tsx" /* webpackChunkName: "component---src-pages-fakty-i-mity-tsx" */),
  "component---src-pages-gwarancja-skutecznosci-tsx": () => import("./../../../src/pages/gwarancja-skutecznosci.tsx" /* webpackChunkName: "component---src-pages-gwarancja-skutecznosci-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kalkulator-bmi-tsx": () => import("./../../../src/pages/kalkulator-bmi.tsx" /* webpackChunkName: "component---src-pages-kalkulator-bmi-tsx" */),
  "component---src-pages-kalkulator-idealnej-wagi-tsx": () => import("./../../../src/pages/kalkulator-idealnej-wagi.tsx" /* webpackChunkName: "component---src-pages-kalkulator-idealnej-wagi-tsx" */),
  "component---src-pages-kalkulator-kalorii-tsx": () => import("./../../../src/pages/kalkulator-kalorii.tsx" /* webpackChunkName: "component---src-pages-kalkulator-kalorii-tsx" */),
  "component---src-pages-kalkulator-whr-tsx": () => import("./../../../src/pages/kalkulator-whr.tsx" /* webpackChunkName: "component---src-pages-kalkulator-whr-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-ludzie-i-wywiady-tsx": () => import("./../../../src/pages/ludzie-i-wywiady.tsx" /* webpackChunkName: "component---src-pages-ludzie-i-wywiady-tsx" */),
  "component---src-pages-mapa-promocji-tsx": () => import("./../../../src/pages/mapa-promocji.tsx" /* webpackChunkName: "component---src-pages-mapa-promocji-tsx" */),
  "component---src-pages-moje-listy-tsx": () => import("./../../../src/pages/moje-listy.tsx" /* webpackChunkName: "component---src-pages-moje-listy-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-promocja-delikatesy-centrum-tsx": () => import("./../../../src/pages/promocja/delikatesy-centrum.tsx" /* webpackChunkName: "component---src-pages-promocja-delikatesy-centrum-tsx" */),
  "component---src-pages-promocja-lidl-tsx": () => import("./../../../src/pages/promocja/lidl.tsx" /* webpackChunkName: "component---src-pages-promocja-lidl-tsx" */),
  "component---src-pages-promocja-tsx": () => import("./../../../src/pages/promocja.tsx" /* webpackChunkName: "component---src-pages-promocja-tsx" */),
  "component---src-pages-promocje-tsx": () => import("./../../../src/pages/promocje.tsx" /* webpackChunkName: "component---src-pages-promocje-tsx" */),
  "component---src-pages-przepisy-tsx": () => import("./../../../src/pages/przepisy.tsx" /* webpackChunkName: "component---src-pages-przepisy-tsx" */),
  "component---src-pages-slownik-tsx": () => import("./../../../src/pages/slownik.tsx" /* webpackChunkName: "component---src-pages-slownik-tsx" */),
  "component---src-pages-sterole-roslinne-tsx": () => import("./../../../src/pages/sterole-roslinne.tsx" /* webpackChunkName: "component---src-pages-sterole-roslinne-tsx" */),
  "component---src-pages-wiedza-tsx": () => import("./../../../src/pages/wiedza.tsx" /* webpackChunkName: "component---src-pages-wiedza-tsx" */),
  "component---src-pages-wszystkie-produkty-tsx": () => import("./../../../src/pages/wszystkie-produkty.tsx" /* webpackChunkName: "component---src-pages-wszystkie-produkty-tsx" */),
  "component---src-pages-wyniki-wyszukiwania-tsx": () => import("./../../../src/pages/wyniki-wyszukiwania.tsx" /* webpackChunkName: "component---src-pages-wyniki-wyszukiwania-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-blog-author-template-tsx": () => import("./../../../src/templates/blog-author-template.tsx" /* webpackChunkName: "component---src-templates-blog-author-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-definition-template-tsx": () => import("./../../../src/templates/definition-template.tsx" /* webpackChunkName: "component---src-templates-definition-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-recipe-article-template-tsx": () => import("./../../../src/templates/recipe-article-template.tsx" /* webpackChunkName: "component---src-templates-recipe-article-template-tsx" */),
  "component---src-templates-recipe-category-template-tsx": () => import("./../../../src/templates/recipe-category-template.tsx" /* webpackChunkName: "component---src-templates-recipe-category-template-tsx" */)
}

